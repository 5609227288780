import { TextInput } from "react-admin";

export const userFilters = [
  <TextInput
    label="File Number"
    source="fileNumber"
    alwaysOn
    name="fileNumber"
    resettable
  />,
  <TextInput label="Email" source="email" alwaysOn name="email" resettable />,
  <TextInput
    label="Mobile"
    source="mobile"
    alwaysOn
    name="mobile"
    resettable
  />,
  <TextInput
    label="First Name"
    source="firstName"
    alwaysOn
    name="firstName"
    resettable
  />,
  <TextInput
    label="Last Name"
    source="lastName"
    alwaysOn
    name="lastName"
    resettable
  />,
];
