import { StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import { colors } from "../../app/colors";

export const genericStyles = StyleSheet.create({
  page: { flexDirection: "column", padding: "10pt" },
  sectionTitle: { fontSize: "16px" },
});

export const QuestionAndAnswer: React.FC<{
  question: string;
  answer?: number;
  potentialScores: number[];
  minGreySection?: number;
}> = ({ question, answer, minGreySection, potentialScores }) => {
  const styles = StyleSheet.create({
    questionAndAnswerContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      borderBottom: "1px solid black",
      minHeight: "40rem",
    },
    questionText: {
      fontSize: "8px",
    },
    questionContainer: {
      display: "flex",
      flex: "1",
      height: "100%",
      justifyContent: "center",
      padding: "8pt",
      borderLeft: "1px solid black",
    },
    answerBox: {
      width: "50rem",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderLeft: "1px solid black",
    },
    greyBackground: {
      backgroundColor: colors.grey.medium,
    },
    knob: {
      width: "10px",
      height: "10px",
      borderRadius: 10,
      borderWidth: 3,
      backgroundColor: "black",
    },
  });

  const CheckedBox = () => {
    return <View style={styles.knob} />;
  };

  const backgroundStyling = (section: number, minSection?: number) => {
    if (minSection && minSection <= section) {
      return styles.greyBackground;
    } else {
      return {};
    }
  };

  return (
    <View wrap={false} style={styles.questionAndAnswerContainer}>
      <View style={styles.questionContainer}>
        <Text style={styles.questionText}>{question}</Text>
      </View>
      {potentialScores.map((score, index) => (
        <View
          key={score}
          style={{
            ...styles.answerBox,
            ...backgroundStyling(score, minGreySection),
            borderRight:
              index === potentialScores.length - 1 ? "1px solid black" : "",
          }}
        >
          {answer === score && <CheckedBox />}
        </View>
      ))}
    </View>
  );
};

export const AnswerLabels: React.FC<{ questions: string[] }> = ({
  questions,
}) => {
  const styles = StyleSheet.create({
    answersContainer: {
      display: "flex",
      flexDirection: "row",
    },
    answerContainer: {
      width: "50rem",
      height: "40rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      borderLeft: "1px solid black",
      borderTop: "1px solid black",
    },
    emptySpace: {
      display: "flex",
      flex: 1,
    },
    answerText: {
      fontSize: "8px",
      padding: "1pt",
    },
    container: {
      display: "flex",
      flexDirection: "row",
      borderBottom: "1px solid black",
    },
  });

  const StyledText: React.FC<{ text: string }> = ({ text }) => {
    return <Text style={styles.answerText}>{text}</Text>;
  };

  return (
    <View style={styles.container}>
      <View style={styles.emptySpace}></View>
      <View style={styles.answersContainer}>
        {questions.map((question, index) => {
          const additionalStyling =
            index === questions.length - 1
              ? { borderRight: "1px solid black" }
              : {};
          return (
            <View
              key={question}
              style={{ ...styles.answerContainer, ...additionalStyling }}
            >
              <StyledText text={question} />
            </View>
          );
        })}
      </View>
    </View>
  );
};
