import { Link as MuiLink } from "@mui/material";
import {
  Datagrid,
  DateField,
  List,
  TextField,
  usePermissions,
  WithRecord,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import { UserBulkActions } from "./userBulkActions";
import { userFilters } from "./userFilters";
import { Pagination } from "../../components/Pagination";
import { checkForRole } from "../../utils/checkRoles";
import { birthdayDateOptions } from "../../utils/dateTime";
import { formatPhoneNumber } from "../Prescreenings/utils";

export const UsersList = props => {
  const { permissions } = usePermissions();
  const navigateTo = useNavigate();

  if (!permissions) {
    return null;
  }
  const isPharmacist = checkForRole("pharmacist", permissions);
  const isTeamLead = checkForRole("teamLead", permissions);

  return (
    <>
      <List
        {...props}
        sort={{ field: "createdAt", order: "DESC" }}
        filters={userFilters}
        perPage={100}
        empty={false}
        pagination={<Pagination />}
      >
        {isPharmacist ? (
          <Datagrid bulkActionButtons={false} sx={{ whiteSpace: "nowrap" }}>
            <WithRecord
              label={"Email"}
              render={record => (
                <MuiLink
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigateTo(`/users/${record.id}/show`)}
                >
                  {record.email}
                </MuiLink>
              )}
            />
            <DateField source="birthday" options={birthdayDateOptions} />
            <TextField source="email" />
          </Datagrid>
        ) : (
          <Datagrid
            bulkActionButtons={isTeamLead ? <UserBulkActions /> : false}
            sx={{ whiteSpace: "nowrap" }}
          >
            <TextField source="fileNumber" />
            <WithRecord
              label={"Email"}
              render={record => (
                <MuiLink
                  sx={{ cursor: "pointer" }}
                  onClick={() => navigateTo(`/users/${record.id}/show`)}
                >
                  {record.email}
                </MuiLink>
              )}
            />
            <WithRecord
              key="mobile"
              label="Mobile"
              render={record => {
                return (
                  <TextField
                    record={{
                      mobile: formatPhoneNumber(record.mobile),
                    }}
                    source="mobile"
                  />
                );
              }}
            />
            <TextField source="firstName" />
            <TextField source="lastName" />
            <TextField source="plan" />
            <TextField source="province" />
          </Datagrid>
        )}
      </List>
    </>
  );
};
