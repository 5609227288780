import { Text, View } from "@react-pdf/renderer";
import React from "react";
import { convertNameToTitle } from "./DataContainer";
import { AllergiesInfo, IPrescreening } from "./Prescreening";
import {
  convertToHumanReadableAnswer,
  medicalHistoryFields,
  ptsdDifficulties,
  socialHistoryFields,
  substanceUseFields,
} from "./PrescreeningPdfSummaryFields";
import { colors } from "../../app/colors";
import { styles } from "../AdhdInfo/cognitoAdhdAssessmentForm/CognitoAdhdAssessmentFormPage";
import { Divider } from "../AdhdInfo/components";
import { convertBooleanToYesNo } from "../AdhdInfo/utils";
import { convertDateToHumanReadable } from "../progress/utils";

// Note this is duplicated in text form in PrescreeningTextSummary.tsx
export const PrescreeningPdfSummary: React.FC<{
  data: IPrescreening;
}> = ({ data }) => {
  const showPtsdDifficulties =
    data.noPtsdSymptoms === false &&
    data.preferNotToAnswerPtsdSymptoms !== true;

  const showFurtherPtsdQuestions =
    data.experiencedTrauma === true &&
    data.noPtsdSymptoms === false &&
    data.preferNotToAnswerPtsdSymptoms === false;

  // Format ETOH info to be easier to display
  if (!data.drinksAlcoholFrequency) {
    data.etoh = "Not answered";
  } else {
    data.etoh =
      data.drinksAlcoholFrequency !== "Never"
        ? `${data.drinksAlcoholFrequency}, ${data.drinksAlcoholDetails} units per session`
        : `${data.drinksAlcoholFrequency}`;
  }

  if (data.hasAllergiesDetails) {
    try {
      data.hasAllergiesDetails = JSON.parse(
        data.hasAllergiesDetails as string
      ) as AllergiesInfo[];
    } catch (e) {
      // do nothing
    }
  }

  // Group medical history into confirmed and denied
  const confirmedMedicalHistoryFields: string[] = [];
  const deniedMedicalHistoryFields: string[] = [];
  medicalHistoryFields.forEach(field => {
    const label = field.label ?? convertNameToTitle(field.name);
    if (data[field.name] === true) {
      confirmedMedicalHistoryFields.push(label);
    } else {
      deniedMedicalHistoryFields.push(label);
    }
  });

  return (
    <View>
      <View>
        <Text style={{ fontSize: "18px", color: colors.green.primary }}>
          SOCIAL HX
        </Text>
        {socialHistoryFields.map(field => (
          <Text key={field.name} style={styles.text}>{`${
            field.label ?? convertNameToTitle(field.name)
          }: ${data[field.name] ?? "None"}`}</Text>
        ))}
      </View>

      <Divider />

      <View>
        <Text style={{ fontSize: "18px", color: colors.green.primary }}>
          MEDICAL HX
        </Text>
        <Text style={styles.text}>
          {`${confirmedMedicalHistoryFields.join(", ")}`}
        </Text>
        <Text
          style={styles.text}
        >{`No known history of: ${deniedMedicalHistoryFields.join(
          ", "
        )}`}</Text>
        {!data.previousSurgicalProcedures && (
          <Text
            style={styles.text}
          >{`Previous surgical procedures: None`}</Text>
        )}
        {data.previousSurgicalProcedures &&
          (data.previousSurgicalProceduresDetails &&
          data.previousSurgicalProceduresDetails.length > 0 ? (
            <View>
              <Text
                style={styles.text}
              >{`Previous surgical procedures details:`}</Text>
              {data.previousSurgicalProceduresDetails.map((field, index) => (
                <View
                  key={`${field.procedure}-${field.date}`}
                  style={{ marginBottom: "5pt" }}
                >
                  <Text style={styles.text}>{`${index + 1}. ${
                    field.procedure
                  }, ${convertDateToHumanReadable(field.date)}`}</Text>
                </View>
              ))}
            </View>
          ) : (
            <Text
              style={styles.text}
            >{`Previous surgical procedures: Details Unknown`}</Text>
          ))}
      </View>

      <Divider />

      <View>
        <Text style={{ fontSize: "18px", color: colors.green.primary }}>
          MEDICATIONS
        </Text>
        {!data.hasPreviousPrescriptions && (
          <Text style={styles.text}>{`Previous medications: None`}</Text>
        )}
        {data.hasPreviousPrescriptions &&
          (data.hasPreviousPrescriptionsDetails &&
          data.hasPreviousPrescriptionsDetails.length > 0 ? (
            <View>
              <Text style={styles.text}>{`Previous medications:`}</Text>
              {data.hasPreviousPrescriptionsDetails.map((field, index) => (
                <View
                  key={`${field.medication}-${field.duration}-${field.sideEffects}`}
                  style={{ marginBottom: "5pt" }}
                >
                  <Text style={styles.text}>{`${index + 1}. ${
                    field.medication
                  }, ${field.duration}, effectiveness: ${
                    field.effectiveness
                  }, side effects: ${field.sideEffects}`}</Text>
                </View>
              ))}
            </View>
          ) : (
            <Text
              style={styles.text}
            >{`Previous medications: Details Unknown`}</Text>
          ))}
        {!data.hasCurrentPrescriptions && (
          <Text style={styles.text}>{`Current medications: None`}</Text>
        )}
        {data.hasCurrentPrescriptions &&
          (data.hasCurrentPrescriptionsDetails &&
          data.hasCurrentPrescriptionsDetails.length > 0 ? (
            <View>
              <Text style={styles.text}>{`Current medications:`}</Text>
              {data.hasCurrentPrescriptionsDetails.map((field, index) => (
                <View
                  key={`${field.medication}-${field.dose}-${field.frequency}`}
                  style={{ marginBottom: "5pt" }}
                >
                  <Text style={styles.text}>{`${index + 1}. ${
                    field.medication
                  }, ${field.dose}, ${field.frequency}`}</Text>
                </View>
              ))}
            </View>
          ) : (
            <Text
              style={styles.text}
            >{`Current medications: Details Unknown`}</Text>
          ))}
      </View>

      <Divider />

      <View>
        <Text style={{ fontSize: "18px", color: colors.green.primary }}>
          SUBSTANCE HX
        </Text>
        {substanceUseFields.map(field => (
          <Text key={field.name} style={styles.text}>{`${
            field.label ?? convertNameToTitle(field.name)
          }: ${data[field.name] ?? "None"}`}</Text>
        ))}
      </View>

      <Divider />

      <View>
        <Text style={{ fontSize: "18px", color: colors.green.primary }}>
          TRAUMA HX
        </Text>
        <Text
          style={styles.text}
        >{`Experienced Trauma: ${convertToHumanReadableAnswer(
          data.experiencedTrauma
        )}`}</Text>
        <Text style={styles.text}>{`Has PTSD Symptoms: ${
          data.preferNotToAnswerPtsdSymptoms === true
            ? "Prefer not to answer"
            : data.noPtsdSymptoms === undefined
            ? "Not Answered"
            : convertBooleanToYesNo(!data.noPtsdSymptoms)
        }`}</Text>
        {showPtsdDifficulties && (
          <View>
            {ptsdDifficulties.map(field => (
              <Text key={field.name} style={styles.text}>{`${
                field.label ?? convertNameToTitle(field.name)
              }: ${convertBooleanToYesNo(data[field.name])}`}</Text>
            ))}
          </View>
        )}
        {showFurtherPtsdQuestions && (
          <View>
            <Text
              style={styles.text}
            >{`Previously supported with trauma based counselling: ${convertToHumanReadableAnswer(
              data.previouslySupportedWithTraumaBasedCounseling
            )}`}</Text>
            <Text
              style={styles.text}
            >{`Seeking trauma focused therapy from Cognito: ${convertToHumanReadableAnswer(
              data.seekingTraumaFocusedTherapyFromCognito
            )}`}</Text>
            {data.seekingTraumaFocusedTherapyFromCognito === true && (
              <Text
                style={styles.text}
              >{`Seeking trauma focused therapy from Cognito details: ${convertToHumanReadableAnswer(
                data.seekingTraumaFocusedTherapyFromCognitoDetails
              )}`}</Text>
            )}
            <Text
              style={styles.text}
            >{`Seeking trauma focused therapy outside of Cognito: ${convertToHumanReadableAnswer(
              data.seekingOrEngagedInTraumaFocusedTherapyOutsideOfCognito
            )}`}</Text>
            {data.seekingOrEngagedInTraumaFocusedTherapyOutsideOfCognito ===
              true && (
              <Text
                style={styles.text}
              >{`Seeking trauma focused therapy outside of Cognito details: ${convertToHumanReadableAnswer(
                data.seekingOrEngagedInTraumaFocusedTherapyOutsideOfCognitoDetails
              )}`}</Text>
            )}
            <Text
              style={styles.text}
            >{`Concerns trauma may impact ability to work with CBT provider: ${convertToHumanReadableAnswer(
              data.traumaConcerns
            )}`}</Text>
            {data.traumaConcerns === true && (
              <Text
                style={styles.text}
              >{`Concerns trauma may impact ability to work with CBT provider details: ${convertToHumanReadableAnswer(
                data.traumaConcernsDetails
              )}`}</Text>
            )}
          </View>
        )}
      </View>

      <Divider />

      <View>
        <Text style={{ fontSize: "18px", color: colors.green.primary }}>
          HOSPITALIZATION / ER
        </Text>
        {!data.goneToEmergencyDueToMentalHealth && (
          <Text
            style={styles.text}
          >{`Has gone to ER due to Mental Health: ${convertBooleanToYesNo(
            data.goneToEmergencyDueToMentalHealth
          )}`}</Text>
        )}
        {data.goneToEmergencyDueToMentalHealthDetails && (
          <Text
            style={styles.text}
          >{`Has gone to ER due to mental health details: ${data.goneToEmergencyDueToMentalHealthDetails}`}</Text>
        )}
      </View>

      <Divider />

      <View>
        <Text style={{ fontSize: "18px", color: colors.green.primary }}>
          ALLERGIES
        </Text>
        {!data.hasAllergies && <Text style={styles.text}>{`None`}</Text>}
        {data.hasAllergies &&
          data.hasAllergiesDetails &&
          (typeof data.hasAllergiesDetails === "string" ? (
            <Text style={styles.text}>{`${
              data.hasAllergies
                ? convertToHumanReadableAnswer(data.hasAllergiesDetails)
                : "None"
            }`}</Text>
          ) : data.hasAllergiesDetails.length > 0 ? (
            <View>
              <Text style={styles.text}>{`Allergies:`}</Text>
              {data.hasAllergiesDetails.map((field, index) => (
                <View
                  key={`${field.allergen}-${field.reaction}-${field.severity}`}
                  style={{ marginBottom: "5pt" }}
                >
                  <Text style={styles.text}>{`${index + 1}. ${
                    field.allergen
                  }, reaction: ${field.reaction}, severity: ${
                    field.severity
                  }`}</Text>
                </View>
              ))}
            </View>
          ) : (
            <Text style={styles.text}>{`Allergies: Details Unknown`}</Text>
          ))}
      </View>

      <Divider />

      <View>
        <Text style={{ fontSize: "18px", color: colors.green.primary }}>
          FAMILY HX
        </Text>
        {!data.familyMemberDiagnosedWithCondition && (
          <Text
            style={styles.text}
          >{`No family members diagnosed with conditions`}</Text>
        )}
        {data.familyMemberDiagnosedWithCondition &&
          (data.familyMemberDiagnosedWithConditionDetails &&
          data.familyMemberDiagnosedWithConditionDetails.length > 0 ? (
            <View>
              <Text
                style={styles.text}
              >{`Family members diagnoses details:`}</Text>
              {data.familyMemberDiagnosedWithConditionDetails.map(
                (field, index) => (
                  <View
                    key={`${field.familyMember}-${field.diagnosis}`}
                    style={{ marginBottom: "5pt" }}
                  >
                    <Text style={styles.text}>{`${index + 1}. ${
                      field.familyMember
                    }, ${field.diagnosis}`}</Text>
                  </View>
                )
              )}
            </View>
          ) : (
            <Text
              style={styles.text}
            >{`Family members diagnoses details: Unknown`}</Text>
          ))}
      </View>

      <Divider />
    </View>
  );
};
