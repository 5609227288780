import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import { CognitoAdhdAssessmentFormContent } from "./cognitoAdhdAssessmentForm/CognitoAdhdAssessmentFormPage";
import { CognitoAdhdAssessmentForm } from "./cognitoAdhdAssessmentForm/types";
import { Divider } from "./components";
import { flagSection } from "./flagWeissSection";
import { CADDRAMedicationQuestions } from "./medicationForm/questions";
import {
  CADDRAMedicationForm,
  CADDRASideEffectScore,
} from "./medicationForm/types";
import {
  getDescriptor as getMedicationDescriptor,
  getMedicationSideEffectDescriptor,
} from "./medicationForm/utils";
import { genericStyles } from "./PdfBuilder";
import { selfReportQuestions } from "./selfReport/questions";
import { SelfReportForm } from "./selfReport/types";
import { calculateScore as calculateASRSScore } from "./selfReport/utils";
import { translateScore as translateSheehanScore } from "./sheehan/SheehanPage";
import { SheehanDisabilityScaleForm } from "./sheehan/types";
import { snapQuestions } from "./snap/questions";
import { SnapForm } from "./snap/types";
import { getDiagnosis as getSnapDiagnosis } from "./snap/utils";
import { weissSymptomRecordIIQuestions } from "./weissSymptomRecordII/questions";
import { WeissSymptomRecordIIForm } from "./weissSymptomRecordII/types";
import { wfirsQuestions } from "./wfirs/questions";
import { WfirsForm } from "./wfirs/types";
import { WenderUtahRatingScaleForm } from "./wurs/types";
import {
  questionsToTakeIntoConsideration as WURSQuestionsToTakeIntoConsideration,
  calculateScore as calculateWURSScore,
} from "./wurs/utils";
import { colors } from "../../app/colors";
import { IPrescreening } from "../Prescreenings/Prescreening";
import { PrescreeningPdfSummary } from "../Prescreenings/PrescreeningPdfSummary";

const styles = StyleSheet.create({
  score: { fontSize: "12px", paddingTop: "5pt" },
  text: { fontSize: "14px", paddingTop: "10pt", fontWeight: "normal" },
  sectionTitle: { fontSize: "16px" },
});

export const SummaryPage: React.FC<{
  data: SelfReportForm &
    SnapForm &
    SheehanDisabilityScaleForm &
    WfirsForm &
    WeissSymptomRecordIIForm &
    CADDRAMedicationForm &
    CognitoAdhdAssessmentForm;
  // There's a duplicate key in WFIRS and WURS so the data doesn't get
  // overridden have WURS be its own data.
  wursData: WenderUtahRatingScaleForm;
  clientName: string;
  clientCreatedAt: Date;
  prescreeningData: IPrescreening;
}> = ({ clientCreatedAt, data, clientName, wursData, prescreeningData }) => {
  const wursScore = calculateWURSScore(wursData);
  const wursScoreDescription = Number.isNaN(wursScore)
    ? "N/A"
    : `${wursScore} / ${WURSQuestionsToTakeIntoConsideration.length * 4}`;

  return (
    <Page size="A4" style={genericStyles.page}>
      <View style={{ display: "flex", alignItems: "center" }}>
        <Text>{`${clientName} Summary`}</Text>
        <Text>{`All answers provided by ${clientName}`}</Text>
      </View>

      <CognitoAdhdAssessmentFormContent
        clientCreatedAt={clientCreatedAt}
        data={data}
        includeWeissSymptomRecordII={true}
      />

      <Divider />

      <PrescreeningPdfSummary data={prescreeningData} />

      <Divider />

      <View>
        <Text style={{ fontSize: "18px", color: colors.green.primary }}>
          CADDRA SCALES
        </Text>
      </View>

      <Divider />

      <View>
        <Text style={styles.sectionTitle}>ASRS</Text>
        {selfReportQuestions.map(questionSet => (
          <View key={questionSet.title}>
            <Text style={styles.score}>
              {`${questionSet.title}: ${calculateASRSScore(
                data,
                questionSet.questions
              )}/${questionSet.questions.length}`}
            </Text>
          </View>
        ))}
      </View>

      <Divider />

      <View>
        <Text style={styles.sectionTitle}>SNAP</Text>
        {!data.cantCompleteReason && (
          <View>
            {data.completedByName && (
              <Text style={styles.score}>
                Completed by: {data.completedByName}
              </Text>
            )}
            {data.relationshipToClient && (
              <Text style={styles.score}>
                Relationship to client: {data.relationshipToClient}
              </Text>
            )}
            {data.relationshipLength && (
              <Text style={styles.score}>
                Length of relationship: {data.relationshipLength}
              </Text>
            )}
          </View>
        )}
        {data.cantCompleteReason ? (
          <View>
            <View style={{ paddingTop: "20pt" }}>
              <Text style={genericStyles.sectionTitle}>Did Not Complete</Text>
              <Text
                style={styles.score}
              >{`Reason: ${data.cantCompleteReason}`}</Text>
            </View>
          </View>
        ) : (
          snapQuestions.map(questionSet => (
            <View key={questionSet.section}>
              <Text style={styles.score}>
                {`${questionSet.title} - ${getSnapDiagnosis(
                  data,
                  questionSet.questions,
                  questionSet.section
                )}`}
              </Text>
            </View>
          ))
        )}
      </View>

      <Divider />

      <View>
        <Text style={styles.sectionTitle}>WURS</Text>
        <Text style={styles.score}>{wursScoreDescription}</Text>
      </View>

      <Divider />

      <View>
        <Text style={styles.sectionTitle}>SHEEHAN</Text>
        {data.hasNotWorkedOrStudiedInPastWeek && (
          <Text style={styles.score}>
            Has not worked in the past week for reasons unrelated to the
            disorder
          </Text>
        )}
        {!data.hasNotWorkedOrStudiedInPastWeek && (
          <Text style={styles.score}>
            {`Work/School: ${
              data.symptomsHaveDisruptedWorkOrSchool
            } (${translateSheehanScore(
              data.symptomsHaveDisruptedWorkOrSchool
            )})`}
          </Text>
        )}
        <Text style={styles.score}>
          {`Social Life/Leisure: ${
            data.symptomsHaveDisruptedSocialLifeOrLeisureActivities
          } (${translateSheehanScore(
            data.symptomsHaveDisruptedSocialLifeOrLeisureActivities
          )})`}
        </Text>
        <Text style={styles.score}>
          {`Family Life / Home: ${
            data.symptomsHaveDisruptedFamilyLifeOrHomeResponsibilities
          } (${translateSheehanScore(
            data.symptomsHaveDisruptedFamilyLifeOrHomeResponsibilities
          )})`}
        </Text>
      </View>

      <Divider />

      <View>
        <Text style={styles.sectionTitle}>WSR</Text>
        {weissSymptomRecordIIQuestions.map(questionSet => {
          const section = flagSection(
            data,
            questionSet.questions,
            questionSet.title,
            false
          );
          if (!section) {
            return null;
          }
          return (
            <View key={`${questionSet.title}-scoring`}>
              <Text style={styles.score}>{section}</Text>
            </View>
          );
        })}
      </View>

      <Divider />

      <View>
        <Text style={styles.sectionTitle}>WFIRS</Text>
        {wfirsQuestions.map(questionSet => {
          const section = flagSection(
            data,
            questionSet.questions,
            questionSet.title,
            true
          );
          if (!section) {
            return null;
          }
          return (
            <View key={`${questionSet.title}-scoring`}>
              <Text style={styles.score}>{section}</Text>
            </View>
          );
        })}
      </View>

      <Divider />

      <View>
        <Text style={styles.sectionTitle}>
          CADDRA Patient ADHD Medication Form
        </Text>
        {data.hasNeverTakenADHDMedication && (
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10pt",
            }}
          >
            <Text>N/A</Text>
          </View>
        )}
        {!data.hasNeverTakenADHDMedication && (
          <View>
            {data.medications && (
              <View>
                {data.medications.map(medication => (
                  <Text style={styles.score} key={medication}>
                    {medication}
                  </Text>
                ))}
              </View>
            )}
            <Text style={styles.score}>
              {`ADHD Symptom Control: ${getMedicationDescriptor(
                data.adhdSymptomControl
              )}`}
            </Text>
            <Text style={styles.score}>
              {`Tolerability of Medication: ${getMedicationDescriptor(
                data.tolerabilityOfMedication
              )}`}
            </Text>
            <Text style={styles.score}>
              {`Quality of Life: ${getMedicationDescriptor(
                data.qualityOfLife
              )}`}
            </Text>
            <Text style={styles.score}>
              {`Additional Comments: ${data.comments ?? "None"}`}
            </Text>
            {CADDRAMedicationQuestions.map(questionSet => (
              <View key={questionSet.title}>
                {questionSet.questions.map(questionInfo => (
                  <View key={questionInfo.field}>
                    {data[questionInfo.field] && (
                      <Text style={styles.score}>
                        {`${
                          questionInfo.question
                        }: ${getMedicationSideEffectDescriptor(
                          data[questionInfo.field] as CADDRASideEffectScore
                        )}`}
                      </Text>
                    )}
                  </View>
                ))}
              </View>
            ))}
            <Text style={styles.score}>
              {`Other Side Effects: ${data.otherSideEffects ?? "None"}`}
            </Text>
            <Text style={styles.score}>
              {`Items to Discuss: ${data.itemsToDiscuss ?? "None"}`}
            </Text>
          </View>
        )}
      </View>
    </Page>
  );
};
