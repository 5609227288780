import { Container, Typography } from "@material-ui/core";
import HelpIcon from "@mui/icons-material/Help";
import React from "react";
import { colors } from "../app/colors";

interface ErrorMessageProps {
  message: string;
  details?: string;
  icon?: React.ReactNode;
}

export const ErrorMessage = (props: ErrorMessageProps) => {
  const { message, details, icon } = props;
  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "20vh",
        gap: "2rem",
      }}
    >
      {icon || (
        <HelpIcon style={{ fontSize: "5rem", color: colors.green.primary }} />
      )}
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "60rem",
          gap: "1rem",
        }}
      >
        <Typography
          variant="h4"
          style={{
            textAlign: "center",
            fontWeight: "bold",
            color: colors.blue.dark,
          }}
        >
          {message}
        </Typography>
        <Typography
          variant="h5"
          style={{ textAlign: "center", color: colors.blue.dark }}
        >
          {details}
        </Typography>
      </Container>
    </Container>
  );
};
