import { Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import React from "react";
import { CognitoAdhdAssessmentForm } from "./types";
import { convertBornToHumanReadable } from "./utils";
import { colors } from "../../../app/colors";
import { convertDateToHumanReadable } from "../../progress/utils";
import { Divider } from "../components";
import { genericStyles } from "../PdfBuilder";
import { convertBooleanToYesNo, onNewAdhdFlow } from "../utils";
import { ExtendedWeissSymptomRecordIIAnswers } from "../weissSymptomRecordII/ExtendedWeissSymptomRecordIIAnswers";
import { WeissSymptomRecordIIForm } from "../weissSymptomRecordII/types";

export const styles = StyleSheet.create({
  text: { fontSize: "14px", paddingTop: "10pt", fontWeight: "normal" },
  sectionTitle: {
    fontSize: "16px",
    paddingTop: "20px",
    paddingBottom: "2px",
    fontWeight: "ultrabold",
    color: colors.green.primary,
  },
});

const StyledText: React.FC<{ children: any }> = ({ children }) => {
  return <Text style={styles.text}>{children}</Text>;
};

// Note this is duplicated in a text format in CognitoAdhdAssessmentFormText.tsx
export const CognitoAdhdAssessmentFormContent: React.FC<{
  data: CognitoAdhdAssessmentForm & WeissSymptomRecordIIForm;
  includeWeissSymptomRecordII?: boolean;
  clientCreatedAt: Date;
}> = ({ clientCreatedAt, data, includeWeissSymptomRecordII }) => {
  if (!onNewAdhdFlow(clientCreatedAt)) {
    return null;
  }

  if (!data.reasonForAdhdAssessment) {
    return (
      <View>
        <Text style={styles.sectionTitle}>
          Client has not begun filling out the Cognito ADHD assessment form
        </Text>
        {includeWeissSymptomRecordII && (
          <ExtendedWeissSymptomRecordIIAnswers data={data} />
        )}
      </View>
    );
  }

  return (
    <View style={{ paddingBottom: "10pt" }}>
      <View>
        <Text style={styles.sectionTitle}>REASON FOR SEEKING ASSESSMENT</Text>
        <StyledText>{data.reasonForAdhdAssessment}</StyledText>
        <StyledText>{data.specificAdhdExperiences}</StyledText>
      </View>
      <Divider />
      <View>
        <Text style={styles.sectionTitle}>CHIEF COMPLAINTS</Text>
        <StyledText>{data.primaryConcerns?.join(", ")}</StyledText>
      </View>
      <Divider />
      <View>
        <Text style={styles.sectionTitle}>HISTORY OF PRESENTING ILLNESS</Text>
        <StyledText>
          {`Previously assessed or diagnosed with ADHD: ${convertBooleanToYesNo(
            data.previouslyAssessedOrDiagnosedWithAdhd
          )}`}
        </StyledText>
        {data.previouslyAssessedOrDiagnosedWithAdhd === true && (
          <StyledText>
            {data.previouslyAssessedOrDiagnosedWithAdhdDetails}
          </StyledText>
        )}
      </View>
      {includeWeissSymptomRecordII && (
        <View>
          <Divider />
          <ExtendedWeissSymptomRecordIIAnswers data={data} />
        </View>
      )}
      <Divider />
      <View>
        <Text style={styles.sectionTitle}>DEVELOPMENTAL HISTORY</Text>
        <StyledText>{`Born: ${convertBornToHumanReadable(
          data.born
        )}`}</StyledText>
        <StyledText>{`Mother consumed substances while pregnant: ${convertBooleanToYesNo(
          data.motherConsumedSubstancesWhilePregnant
        )}`}</StyledText>
        {data.motherConsumedSubstancesWhilePregnant === true && (
          <StyledText>{`${data.motherConsumedSubstancesWhilePregnantDetails}`}</StyledText>
        )}
        <StyledText>{`Gross motor difficulties: ${convertBooleanToYesNo(
          data.hadGrossMotorDifficulties
        )}`}</StyledText>
        {data.hadGrossMotorDifficulties === true && (
          <StyledText>{`${data.hadGrossMotorDifficultiesDetails}`}</StyledText>
        )}
        <StyledText>{`Fine motor difficulties: ${convertBooleanToYesNo(
          data.hadFineMotorDifficulties
        )}`}</StyledText>
        {data.hadFineMotorDifficulties === true && (
          <StyledText>{`${data.hadFineMotorDifficultiesDetails}`}</StyledText>
        )}
        <StyledText>{`Language difficulties: ${convertBooleanToYesNo(
          data.hadLanguageDifficulties
        )}`}</StyledText>
        {data.hadLanguageDifficulties === true && (
          <StyledText>{`${data.hadLanguageDifficultiesDetails}`}</StyledText>
        )}
        <StyledText>{`Off behaviours: ${convertBooleanToYesNo(
          data.hadOffBehaviours
        )}`}</StyledText>
        {data.hadOffBehaviours === true && (
          <StyledText>{`${data.hadOffBehavioursDetails}`}</StyledText>
        )}
        <StyledText>{`Childhood temperament: ${data.childhoodTemperament}`}</StyledText>
      </View>
      <Divider />
      <View>
        <Text style={styles.sectionTitle}>FAMILY HISTORY</Text>
        <StyledText>{`Born and raised: ${data.bornAndRaisedLocation} ${data.immediateFamilyMembers}`}</StyledText>
        <StyledText>{`Family Atmosphere: ${data.childhoodAndFamilyEnvironment}`}</StyledText>
        <StyledText>{`Family Strengths: ${data.positiveAspectsOfFamily}`}</StyledText>
        <StyledText>{`Attitude towards Chores and Rules: ${data.choresAndFollowingRules}`}</StyledText>
        <StyledText>{`Discipline in Upbringing: ${data.disciplineAndRules}`}</StyledText>
        <StyledText>{`Family Stressors and Coping: ${data.howDidFamilyHandleDifficulties} ${data.violenceWithinFamilyEnvironment}`}</StyledText>
        <StyledText>
          {`Parents' Relationship: ${data.parentRelationshipDescription}`}
        </StyledText>
        <StyledText>{`Relationship with Parents/Guardians: ${data.relationshipWithParents}`}</StyledText>
        <StyledText>{`Relationship with Siblings: ${
          data.hasSiblings === false
            ? "Does not have siblings"
            : data.relationshipWithSiblings
        }`}</StyledText>
        <StyledText>{`Current Family Stressors: ${
          data.currentFamilyLifeStressors === true
            ? data.currentFamilyLifeStressorsDetails
            : "No stressors"
        }`}</StyledText>
        <StyledText>{`Relationship with Current Partner: ${
          data.hasAPartner === true
            ? data.relationshipWithCurrentPartner
            : "Does not currently have a partner"
        }`}</StyledText>
        <StyledText>{`Parenting Style Strengths and Challenges: ${
          data.isAParent === false
            ? "Is not a parent"
            : data.strengthsAndWeaknessesOfParentingStyle
        }`}</StyledText>
      </View>
      <Divider />
      <View>
        <Text style={styles.sectionTitle}>EDUCATION</Text>
        <Text style={styles.sectionTitle}>
          Elementary (Kindergarten to Grade 6)
        </Text>
        <StyledText>{`Academics: ${data.elementaryAcademicStatus}`}</StyledText>
        <StyledText>{`Homework experience/attitude: ${data.elementaryHomeworkExperience}`}</StyledText>
        <StyledText>{`Elementary class difficulties: ${convertBooleanToYesNo(
          data.hadElementaryClassDifficulties
        )}`}</StyledText>
        {(data.hadElementaryClassDifficulties === true) === true && (
          <StyledText>{data.hadElementaryClassDifficultiesDetails}</StyledText>
        )}
        <StyledText>{`Report card comments: ${data.elementaryReportCardComments}`}</StyledText>
        <StyledText>{`Elementary school additional support: ${convertBooleanToYesNo(
          data.hadElementaryAdditionalSupport
        )}`}</StyledText>
        {(data.hadElementaryAdditionalSupport === true) === true && (
          <StyledText>{data.hadElementaryAdditionalSupportDetails}</StyledText>
        )}
        <StyledText>{`Peer interactions: ${data.elementaryPeerInteraction}`}</StyledText>

        <Text style={{ ...styles.sectionTitle, paddingTop: "30pt" }}>
          Middle School / High School (Grade 6-12)
        </Text>
        <StyledText>{`Academics: ${data.secondarySchoolAcademicStatus}`}</StyledText>
        <StyledText>
          {`Homework experience/attitude: ${data.secondarySchoolHomeworkExperience}`}
        </StyledText>
        <StyledText>{`Secondary school class difficulties: ${convertBooleanToYesNo(
          data.hadSecondarySchoolClassDifficulties
        )}`}</StyledText>
        {data.hadSecondarySchoolClassDifficulties === true && (
          <StyledText>
            {data.hadSecondarySchoolClassDifficultiesDetails}
          </StyledText>
        )}
        <StyledText>{`Grades: ${data.secondarySchoolGrades}`}</StyledText>
        <StyledText>{`Secondary school additional support: ${convertBooleanToYesNo(
          data.hadSecondarySchoolAdditionalSupport
        )}`}</StyledText>
        {data.hadSecondarySchoolAdditionalSupport === true && (
          <StyledText>
            {data.hadSecondarySchoolAdditionalSupportDetails}
          </StyledText>
        )}
        <StyledText>{`Secondary school additional accommodations: ${convertBooleanToYesNo(
          data.requiredSecondarySchoolAccommodations
        )}`}</StyledText>
        {data.requiredSecondarySchoolAccommodations === true && (
          <StyledText>
            {data.requiredSecondarySchoolAccommodationsDetails}
          </StyledText>
        )}

        <Text style={{ ...styles.sectionTitle, paddingTop: "30pt" }}>
          Post Secondary
        </Text>
        <StyledText>{`Attended post secondary: ${convertBooleanToYesNo(
          data.attendedPostSecondary
        )}`}</StyledText>
        {data.attendedPostSecondary === true && (
          <View>
            <StyledText>{`Field: ${data.postSecondaryField}`}</StyledText>
            <StyledText>
              {`Lecture experience: ${data.postSecondaryLectureExperience}`}
            </StyledText>
            <StyledText>{`Difficulties: ${data.postSecondaryDifficulties}`}</StyledText>
            <StyledText>{`Reached post secondary potential: ${convertBooleanToYesNo(
              data.reachedPostSecondaryPotential
            )}`}</StyledText>
            {!data.reachedPostSecondaryPotential === true && (
              <StyledText>
                {data.reachedPostSecondaryPotentialDetails}
              </StyledText>
            )}
          </View>
        )}
      </View>
      <Divider />
      <View>
        <Text style={styles.sectionTitle}>EMPLOYMENT</Text>
        <StyledText>{data.currentEmploymentStatus}</StyledText>
        <StyledText>{`Estimated number of past jobs: ${data.estimatedNumberOfPastJobs}`}</StyledText>
        <StyledText>{`Longest employment length: ${data.longestEmploymentLength}`}</StyledText>
        <StyledText>{`Current position strengths: ${data.currentPositionStrengths}`}</StyledText>
        <StyledText>{`Current position weaknesses: ${data.currentPositionWeaknesses}`}</StyledText>
        <StyledText>{`Work difficulties: ${convertBooleanToYesNo(
          data.hasWorkDifficulties
        )}`}</StyledText>
        {data.hasWorkDifficulties === true && (
          <StyledText>{`${data.hasWorkDifficultiesDetails}`}</StyledText>
        )}
        <StyledText>{`Workplace accommodations: ${convertBooleanToYesNo(
          data.requiresWorkplaceAccommodations
        )}`}</StyledText>
        {data.requiresWorkplaceAccommodations === true && (
          <StyledText>{`${data.requiresWorkplaceAccommodationsDetails}`}</StyledText>
        )}
      </View>
      <Divider />
      <View>
        <Text style={styles.sectionTitle}>CAR SAFETY</Text>
        <StyledText>{`Drives: ${convertBooleanToYesNo(
          data.drives
        )}`}</StyledText>
        {data.drives === false && (
          <StyledText>{`Reason for not driving: ${data.reasonForNotDriving}`}</StyledText>
        )}
        {data.drives === true && (
          <View>
            <StyledText>{`Accidents or near misses as primary driver: ${convertBooleanToYesNo(
              data.hadAccidentsWhilePrimaryDriver
            )}`}</StyledText>
            {data.hadAccidentsWhilePrimaryDriver === true && (
              <StyledText>{`${data.hadAccidentsWhilePrimaryDriverDetails}`}</StyledText>
            )}
            <StyledText>{`Dangerous driving: ${convertBooleanToYesNo(
              data.dangerousDriver
            )}`}</StyledText>
            {data.dangerousDriver === true && (
              <StyledText>{`${data.dangerousDriverDetails}`}</StyledText>
            )}
          </View>
        )}
        <StyledText>{`Accident prone: ${convertBooleanToYesNo(
          data.accidentProne
        )}`}</StyledText>
        {data.accidentProne === true && (
          <StyledText>{`${data.accidentProneDetails}`}</StyledText>
        )}
      </View>
      <Divider />
      <View>
        <Text style={styles.sectionTitle}>LIFESTYLE</Text>
        <StyledText>{`Exercise routine: ${data.exerciseRoutine}`}</StyledText>
        <StyledText>{`Difficulties with exercise routine: ${convertBooleanToYesNo(
          data.hasDifficultiesWithExerciseRoutine
        )}`}</StyledText>
        <StyledText>{`Favourite types of exercise: ${data.favouriteTypesOfExercise}`}</StyledText>
        <StyledText>{`Regularly engaging in extreme sports: ${convertBooleanToYesNo(
          data.regularlyEngagingInExtremeSports
        )}`}</StyledText>
        <StyledText>{`Typical eating pattern: ${data.typicalEatingPattern}`}</StyledText>
        <StyledText>{`Eating pattern difficulties: ${convertBooleanToYesNo(
          data.hasEatingPatternDifficulties
        )}`}</StyledText>
        <StyledText>
          {`Self care difficulties: ${convertBooleanToYesNo(
            data.hasSelfCareDifficulties
          )}`}
        </StyledText>
        {data.hasSelfCareDifficulties === true && (
          <StyledText>{`${data.hasSelfCareDifficultiesDetails}`}</StyledText>
        )}
        <StyledText>{`Hobbies: ${data.hobbies}`}</StyledText>
        <StyledText>{`Becomes bored with hobbies: ${convertBooleanToYesNo(
          data.becomesBoredWithHobbies
        )}`}</StyledText>
      </View>
    </View>
  );
};

export const CognitoAdhdAssessmentFormPage: React.FC<{
  data: CognitoAdhdAssessmentForm;
  clientName?: string;
  clientCreatedAt: Date;
}> = ({ data, clientName, clientCreatedAt }) => {
  return (
    <Page size="A4" style={genericStyles.page}>
      <View>
        <View style={{ display: "flex", alignItems: "center" }}>
          <Text>Cognito ADHD Assessment Form</Text>
        </View>
        <View style={{ display: "flex", alignItems: "center" }}>
          <Text>{`Completed by ${clientName}`}</Text>
        </View>
        <View style={{ display: "flex", alignItems: "center" }}>
          {data.createdAt && (
            <Text>{`Completed on ${convertDateToHumanReadable(
              data.createdAt
            )}`}</Text>
          )}
        </View>
      </View>
      <CognitoAdhdAssessmentFormContent
        data={data}
        clientCreatedAt={clientCreatedAt}
      />
    </Page>
  );
};
