import { Text, View } from "@react-pdf/renderer";
import React from "react";
import {
  attentionQuestions,
  hyperActivityAndImpulsivityQuestions,
} from "./questions";
import { WeissSymptomRecordIIForm, WeissSymptomRecordIIScores } from "./types";
import { colors } from "../../../app/colors";
import { Divider } from "../components";
import { convertBooleanToYesNo } from "../utils";

const convertToScore = (score: WeissSymptomRecordIIScores) => {
  if (score === -1) {
    return "N/A";
  }
  if (score === 0) {
    return "None";
  }
  if (score === 1) {
    return "Mild";
  }
  if (score === 2) {
    return "Moderate";
  }
  if (score === 3) {
    return "Severe";
  }
};

export const ExtendedWeissSymptomRecordIIAnswers: React.FC<{
  data: WeissSymptomRecordIIForm;
}> = ({ data }) => {
  return (
    <View>
      {[...attentionQuestions, ...hyperActivityAndImpulsivityQuestions].map(
        questionSet => {
          // If N/A, None, or Mild severity and they didn't have the symptom as
          // a child don't show further details.
          if (
            (data[questionSet.field] === -1 ||
              data[questionSet.field] === 0 ||
              data[questionSet.field] === 1 ||
              data[questionSet.field] === undefined ||
              data[questionSet.field] === null) &&
            !data[`${questionSet.field}AsChild`]
          ) {
            return null;
          }
          return (
            <View
              style={{ paddingTop: "20pt" }}
              key={`${questionSet.question}`}
            >
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                }}
              >
                <Text
                  style={{
                    fontSize: "16px",
                    color: colors.green.primary,
                  }}
                >
                  {questionSet.question}
                </Text>
                <Text
                  style={{
                    marginLeft: "5pt",
                    fontSize: "14px",
                  }}
                >
                  {` - ${convertToScore(
                    data[questionSet.field] as WeissSymptomRecordIIScores
                  )}`}
                </Text>
              </View>
              {data[`${questionSet.field}DailyImpact`] && (
                <Text style={{ paddingTop: "5pt" }}>
                  <Text style={{ fontSize: "14pt" }}>Impact as adult</Text>
                  <Text style={{ fontSize: "12pt" }}>{` ${
                    data[`${questionSet.field}DailyImpact`]
                  }`}</Text>
                </Text>
              )}
              {data[`${questionSet.field}AsChild`] === false && (
                <Text style={{ paddingTop: "5pt" }}>
                  <Text style={{ fontSize: "14pt" }}>
                    Experienced as a child:
                  </Text>
                  <Text style={{ fontSize: "12pt" }}>{` ${convertBooleanToYesNo(
                    data[`${questionSet.field}AsChild`]
                  )}`}</Text>
                </Text>
              )}
              {data[`${questionSet.field}AsChild`] && (
                <View>
                  <Text style={{ paddingTop: "5pt" }}>
                    <Text style={{ fontSize: "14pt" }}>Impact as child:</Text>
                    <Text style={{ fontSize: "12pt" }}>{` ${
                      data[`${questionSet.field}ChildhoodImpact`]
                    }`}</Text>
                  </Text>
                </View>
              )}
              <Divider />
            </View>
          );
        }
      )}
      {data.ageWhenAttentionSymptomsBegan && (
        <Text style={{ paddingTop: "25pt" }}>
          <Text style={{ fontSize: "14pt" }}>
            Age when attention ADHD symptoms began:
          </Text>
          <Text
            style={{ fontSize: "12pt" }}
          >{` ${data.ageWhenAttentionSymptomsBegan}`}</Text>
        </Text>
      )}
      {data.ageWhenHyperactivitySymptomsBegan && (
        <Text style={{ paddingTop: "5pt" }}>
          <Text style={{ fontSize: "14pt" }}>
            Age when hyperactivity ADHD symptoms began:
          </Text>
          <Text
            style={{ fontSize: "12pt" }}
          >{` ${data.ageWhenHyperactivitySymptomsBegan}`}</Text>
        </Text>
      )}
    </View>
  );
};
