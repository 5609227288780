import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  FormLabel,
  Typography,
} from "@mui/material";
import axios, { AxiosRequestHeaders } from "axios";
import React, { memo, useEffect, useState } from "react";
import {
  CheckboxGroupInput,
  DateInput,
  FileField,
  FileInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  SaveButton,
  SelectArrayInput,
  SimpleForm,
  TextInput,
  TextInputProps,
  email,
  required,
  useInput,
  useNotify,
} from "react-admin";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Container } from "./client-intake.styles";
import { ClientIntakeErrorMessage } from "./ClientIntakeErrorMessage";
import {
  conditions,
  noAngleBrackets,
  translateErrorMessage,
} from "./ClientIntakeUtils";
import { colors } from "./colors";
import { ConfirmationDialog } from "./ConfirmationDialog";
import logo from "../assets/cognitoTextLogo.png";
import {
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_INITIAL_CBT_SESSIONS,
  REACT_APP_PRICE_ID,
  REACT_APP_THIRD_PARTY_LOGO_FILENAME,
  useHttpClient,
} from "../utils";
import {
  handleFileRejections,
  MAX_FILE_SIZE,
  MAX_FILES,
  SUPPORTED_FILE_TYPES,
} from "../utils/fileHandler";
import { formatPhoneNumber } from "../views/Prescreenings/utils";

const WrappedPhoneInput = ({ source, setPhoneNumberError }) => {
  const { field } = useInput({
    source,
  });
  return (
    <PhoneInput
      inputProps={{ required: true }}
      country="ca"
      onlyCountries={["ca"]}
      disableDropdown
      value={field.value}
      onChange={value => {
        field.onChange(value);
        setPhoneNumberError("");
      }}
    />
  );
};

const StyledUnlabeledTextInput: React.FC<TextInputProps> = props => {
  return (
    <TextInput
      sx={{ "& input": { paddingTop: "10px" } }}
      label={false}
      validate={required()}
      {...props}
    />
  );
};

type DropzoneFiles = {
  rawFile: File;
  src?: string;
  title?: string;
};

const ClientIntake = () => {
  const { httpClient } = useHttpClient();
  const notify = useNotify();
  const { user, getAccessTokenSilently } = useAuth0();
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [httpErrorMessage, setHttpErrorMessage] = useState("");
  const [showClientNotInformedError, setShowClientNotInformedError] =
    useState(false);
  const [showTraumaExplanation, setShowTraumaExplanation] = useState(false);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [thirdPartyLogo, setThirdPartyLogo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [values, setValues] = useState<Record<string, string | string[]>>({});
  const [files, setFiles] = useState<DropzoneFiles[]>([]);

  useEffect(() => {
    const getImage = async () => {
      const response = await import(
        `../assets/${REACT_APP_THIRD_PARTY_LOGO_FILENAME}`
      );
      setThirdPartyLogo(response.default);
    };
    if (REACT_APP_THIRD_PARTY_LOGO_FILENAME) {
      getImage();
    }
  }, []);

  const handleSubmission = async (inputValues: any) => {
    if (!inputValues.mobile) {
      setPhoneNumberError("Please enter a phone number");
      return;
    }
    if (inputValues.mobile.length < 11) {
      setPhoneNumberError("Invalid phone number");
      return;
    }
    const { messages: errorMessages, includeTraumaExplanation } =
      translateErrorMessage({ ...inputValues });
    const didNotDiscussWithClient = inputValues.discussedWithClient === "no";
    if (
      errorMessages.length ||
      includeTraumaExplanation ||
      didNotDiscussWithClient
    ) {
      setSubmitted(true);
      setValues({
        adminName: inputValues.adminName,
        adminEmail: inputValues.adminEmail,
      });
      if (didNotDiscussWithClient) {
        setShowClientNotInformedError(didNotDiscussWithClient);
        return;
      }
      setShowTraumaExplanation(includeTraumaExplanation);
      setErrorMessage(errorMessages.join(", "));
      await httpClient(`${REACT_APP_AUTH0_AUDIENCE}/failed_referral_reasons`, {
        method: "POST",
        body: JSON.stringify({
          adminName: inputValues.adminName,
          adminEmail: inputValues.adminEmail,
          adminRegion: user?.email,
          clientName: `${inputValues.clientFirstName} ${inputValues.clientLastName}`,
          clientBirthday: inputValues.birthday,
          ineligibilityReasons: errorMessages,
        }),
      });
      return;
    }
    const { clientFiles, ...rest } = inputValues;
    setValues(rest);
    if (clientFiles) {
      setFiles(clientFiles);
    }
    setShowConfirmationDialog(true);
  };

  const closeConfirmationDialog = () => {
    setIsLoading(false);
    setShowConfirmationDialog(false);
  };

  const onConfirmationDialogClose = async (confirmed: boolean) => {
    if (!confirmed) {
      closeConfirmationDialog();
      return;
    }

    setIsLoading(true);

    let initial_cbt_sessions;
    if (REACT_APP_INITIAL_CBT_SESSIONS) {
      // this property is only defined in IH
      initial_cbt_sessions = JSON.parse(REACT_APP_INITIAL_CBT_SESSIONS);
    }

    const formData = new FormData();
    files.forEach(f => {
      formData.append("files", f.rawFile);
    });

    const data = {
      price_id: REACT_APP_PRICE_ID,
      name: `${values.clientFirstName} ${values.clientLastName}`,
      email: values.clientEmail,
      user_details: JSON.stringify({
        preferredName: values.preferredName,
        birthday: values.birthday,
        mobile: values.mobile,
      }),
      admin_details: JSON.stringify({
        email: values.adminEmail,
        name: values.adminName,
        region: user?.email,
      }),
      initial_cbt_sessions,
      communication_about_program: values.communicationAboutProgram,
      client_notes: values.clientNotes,
      referralReasons: JSON.stringify(values.referralReasons),
    };
    Object.entries(data).forEach(([key, value]) => {
      if (value !== undefined) {
        formData.append(key, value);
      }
    });

    const accessToken = await getAccessTokenSilently();
    const headers: AxiosRequestHeaders = {
      Authorization: `Bearer ${accessToken}`,
    };

    try {
      await axios.request({
        method: "POST",
        url: `${REACT_APP_AUTH0_AUDIENCE}/signup_via_third_party`,
        headers,
        data: formData,
      });
      setSubmitted(true);
    } catch (e: any) {
      if (
        e?.response?.data?.message ===
        "An account with this email address already exists."
      ) {
        const targetElement = document.querySelector("#clientEmail");
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        setEmailError(e?.response?.data?.message);
        closeConfirmationDialog();
        return;
      }
      setSubmitted(true);
      setHttpErrorMessage(e.message);
    } finally {
      setValues({
        adminName: values.adminName,
        adminEmail: values.adminEmail,
      });
      setFiles([]);
      closeConfirmationDialog();
    }
  };

  const referNewClientButton = () => {
    return (
      <Button
        variant="contained"
        fullWidth={false}
        onClick={() => {
          setSubmitted(false);
          setErrorMessage("");
          setHttpErrorMessage("");
          setShowTraumaExplanation(false);
          setShowClientNotInformedError(false);
        }}
      >
        Refer Another Client +
      </Button>
    );
  };

  const renderYesNoRadio = (title: string, key: string) => {
    return (
      <RadioButtonGroupInput
        validate={required()}
        sx={{
          marginTop: 0,
          marginBottom: "0.25rem",
          // Our own css selectors get overridden by MUI's more specific css
          // rules so we'll default to using !important.
          legend: { transform: "unset !important" },
          // react admin puts an awkward "Required" text under the radio buttons so
          // we'll hide it for now.
          "& p": { height: 0, visibility: "hidden" },
        }}
        source={key}
        label={title}
        aria-labelledby={`${key}-radio-buttons-group-label`}
        name={key}
        choices={[
          { id: "yes", name: "Yes" },
          { id: "no", name: "No" },
        ]}
      />
    );
  };

  const confirmationDialogContent = () => {
    return (
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        minWidth={"30rem"}
      >
        {isLoading ? (
          <CircularProgress style={{ color: colors.green.primary }} />
        ) : (
          <Box>
            <Box>
              This is confirmation that you want to refer{" "}
              {values.clientFirstName} {values.clientLastName} (
              <strong>{values.clientEmail}</strong>) to Cognito Health.
              <ul>
                <li style={{ marginBottom: "1rem" }}>
                  You have uploaded <strong>{files.length}</strong>{" "}
                  {files.length === 1 ? "file" : "files"} for the client. Is
                  this correct?
                </li>
                <li style={{ marginBottom: "1rem" }}>
                  An email will automatically be sent to{" "}
                  <strong>{values.clientEmail}</strong> in order for the client
                  to create their account.
                </li>
                <li>
                  A text message will also be sent to{" "}
                  <strong>{formatPhoneNumber(values.mobile)}</strong> to notify
                  them that they have been referred to Cognito Health.
                </li>
              </ul>
            </Box>
            {values.clientEmail.includes("@islandhealth.ca") && (
              <Box sx={{ marginTop: "1rem", color: "red", fontWeight: "bold" }}>
                You have listed the client's email as an Island Health email. Is
                this correct?
              </Box>
            )}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box>
      {showConfirmationDialog && (
        <ConfirmationDialog
          open
          disableCancel={isLoading}
          disabledOk={isLoading}
          onCloseDialog={onConfirmationDialogClose}
          content={confirmationDialogContent}
        />
      )}
      {!submitted ? (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "0.5rem",
            }}
          >
            <img
              alt="Cognito Logo"
              style={{ width: "300px", height: "30px" }}
              src={logo}
            />
            {thirdPartyLogo && (
              <img
                alt="Third Party logo"
                style={{ width: "200px", height: "100px" }}
                src={thirdPartyLogo}
              />
            )}
          </Box>
          <SimpleForm toolbar={false} onSubmit={handleSubmission}>
            <FormControl
              required
              fullWidth
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              <FormLabel id={"adminName"}>{"Your Name"}</FormLabel>
              <StyledUnlabeledTextInput
                validate={[noAngleBrackets, required()]}
                source="adminName"
                defaultValue={values.adminName}
              />
            </FormControl>
            <FormControl
              required
              fullWidth
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              <FormLabel id={"adminEmail"}>{"Your Email"}</FormLabel>
              <StyledUnlabeledTextInput
                validate={[required(), email("Must be a valid email")]}
                source="adminEmail"
                defaultValue={values.adminEmail}
                type="email"
              />
            </FormControl>
            <FormControl
              required
              fullWidth
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              <FormLabel id={"clientFirstName"}>
                {"Client First Name"}
              </FormLabel>
              <StyledUnlabeledTextInput
                validate={[noAngleBrackets, required()]}
                source="clientFirstName"
              />
            </FormControl>
            <FormControl
              required
              fullWidth
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              <FormLabel id={"clientLastName"}>{"Client Last Name"}</FormLabel>
              <StyledUnlabeledTextInput
                validate={[noAngleBrackets, required()]}
                source="clientLastName"
              />
            </FormControl>
            <FormControl fullWidth style={{ marginTop: 0, marginBottom: 0 }}>
              <FormLabel id={"preferredName"}>
                {"Client Preferred Name"}
              </FormLabel>
              <StyledUnlabeledTextInput
                source="preferredName"
                validate={[noAngleBrackets]}
              />
            </FormControl>
            <FormControl
              error={!!emailError}
              required
              fullWidth
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              {!!emailError && (
                <FormHelperText
                  sx={{ marginLeft: 0 }}
                  id="component-error-text"
                >
                  {emailError}
                </FormHelperText>
              )}
              <FormLabel id={"clientEmail"}>{"Client Email"}</FormLabel>
              <StyledUnlabeledTextInput
                source="clientEmail"
                type="email"
                onChange={() => {
                  setEmailError("");
                }}
                validate={[required(), email("Must be a valid email")]}
                error={!!emailError}
              />
            </FormControl>
            <FormControl required>
              <FormLabel id={"birthday"}>{"Client Date of Birth"}</FormLabel>
              <DateInput
                sx={{
                  input: { "padding-top": "5px", "padding-bottom:": "5px" },
                }}
                helperText={false}
                validate={required()}
                label={false}
                source="birthday"
              />
            </FormControl>
            <FormControl
              error={!!phoneNumberError}
              required
              sx={{ marginTop: "1rem", marginBottom: "2rem" }}
            >
              <FormLabel id={`mobile`}>{"Client Phone Number"}</FormLabel>
              {!!phoneNumberError && (
                <FormHelperText
                  sx={{ marginLeft: 0 }}
                  id="component-error-text"
                >
                  {phoneNumberError}
                </FormHelperText>
              )}
              <WrappedPhoneInput
                source="mobile"
                setPhoneNumberError={setPhoneNumberError}
              />
            </FormControl>
            <FormControl
              fullWidth
              required
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              <FormLabel id={"referralReasons"}>
                {"Reasons for Referral"}
              </FormLabel>
              <CheckboxGroupInput
                validate={required()}
                source="referralReasons"
                label={false}
                choices={[
                  { id: "anxiety", name: "Anxiety" },
                  { id: "depression", name: "Depression" },
                  { id: "insomnia", name: "Insomnia" },
                ]}
              />
            </FormControl>
            <FormControl
              fullWidth
              required
              style={{ marginTop: 0, marginBottom: 0 }}
            >
              <FormLabel id={"otherConditions"}>
                {
                  "Does the client have a condition, diagnosed or highly suspected, which would make them unlikely to benefit from our program?"
                }
              </FormLabel>
              <SelectArrayInput
                validate={required()}
                sx={{ paddingBottom: "10px" }}
                helperText="SELECT ALL THAT APPLY"
                source="otherConditions"
                label={"Conditions"}
                choices={conditions}
              />
            </FormControl>
            {renderYesNoRadio(
              "Has this referral been discussed directly with the client?",
              "discussedWithClient"
            )}
            <FormDataConsumer>
              {({ formData }) =>
                formData.discussedWithClient === "yes" ? (
                  <FormControl
                    required
                    fullWidth
                    style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    <FormLabel id={"communicationAboutProgram"}>
                      {
                        "Please specify what was discussed with the patient regarding treatment goals"
                      }
                    </FormLabel>
                    <StyledUnlabeledTextInput
                      autoFocus
                      source="communicationAboutProgram"
                    />
                  </FormControl>
                ) : null
              }
            </FormDataConsumer>
            {renderYesNoRadio(
              "Is the client willing to engage in psychotherapy?",
              "willingToEngageInPsychotherapy"
            )}
            {renderYesNoRadio(
              "Is the client's primary concern related to possible complex trauma or PTSD (i.e. intrusive memories of the event/nightmares about the event, hyper-arousal or exaggerated startle response, behavioural or interpersonal problems)?",
              "trauma"
            )}
            {renderYesNoRadio(
              "Is the client seeking Trauma Counseling or a Clinical Counselor?",
              "seekingTraumaCounseling"
            )}
            {renderYesNoRadio(
              "Is the client expressing active thoughts of self harm or suicide?",
              "suicidalIdeation"
            )}
            {renderYesNoRadio(
              "Can the client be supported with limited medical supervision and care coordination?",
              "limitedSupervision"
            )}
            {renderYesNoRadio(
              "The client understands they will not be seen or referred to a psychiatrist within Cognito Health.",
              "understandsWillNotSeePsychiatrist"
            )}
            {renderYesNoRadio(
              "The client understands Cognito Health does not replace primary care, they may be referred to an Urgent Primary Care Centre (UPCC) or Walk-In Clinic (WIC) if they do not have primary care attachment.",
              "doesNotReplacePrimaryCare"
            )}
            {renderYesNoRadio(
              "In the event of program completion or discharge, the client will be referred back to MHSU services, self referral, and the professional relationship with Cognito Health will be concluded.",
              "clientOwnership"
            )}
            <FormControl fullWidth style={{ marginTop: 0, marginBottom: 0 }}>
              <FormLabel id={"clientNotes"}>{"Client Notes"}</FormLabel>
              <StyledUnlabeledTextInput
                source={"clientNotes"}
                validate={undefined}
              />
            </FormControl>
            <div style={{ marginBottom: "1rem" }}>
              <Container>
                <FileInput
                  source="clientFiles"
                  label="Client Files"
                  placeholder={
                    <>
                      Click <strong>here</strong> to select up to{" "}
                      <strong>10</strong> files to upload from your computer.
                      Only jpg/jpeg, png, pdf, and heic/heif files are accepted.
                    </>
                  }
                  accept={SUPPORTED_FILE_TYPES}
                  multiple
                  maxSize={MAX_FILE_SIZE}
                  options={{
                    noDrag: true,
                    maxFiles: MAX_FILES,
                    onDropRejected: fileRejections =>
                      handleFileRejections(fileRejections, notify),
                  }}
                >
                  <FileField source="src" title="title" />
                </FileInput>
              </Container>
            </div>
            <SaveButton label="Submit" type="submit" />
          </SimpleForm>
        </>
      ) : errorMessage ||
        showTraumaExplanation ||
        httpErrorMessage ||
        showClientNotInformedError ? (
        <Box
          data-test="ClientIntake-error-container"
          display="flex"
          flexDirection={"column"}
          justifyContent="center"
          alignItems={"center"}
        >
          <ClientIntakeErrorMessage
            errorMessage={errorMessage}
            showClientNotInformedError={showClientNotInformedError}
            httpErrorMessage={httpErrorMessage}
            showTraumaExplanation={showTraumaExplanation}
          />
          {referNewClientButton()}
        </Box>
      ) : (
        <Box
          data-test="ClientIntake-success-container"
          display="flex"
          flexDirection={"column"}
          justifyContent="center"
          alignItems={"center"}
        >
          <Typography
            sx={{ backgroundColor: "rgba(0,204,0,0.6)" }}
            textAlign={"center"}
            variant="h5"
            fontWeight={"bold"}
            padding="1rem"
            width={"100%"}
          >
            CLIENT HAS BEEN REFERRED
          </Typography>
          <Typography variant="h5" margin="1rem 1rem 1rem 0">
            {`Thank you for referring this client. An email has been sent to ${user?.email}
            confirming this client has been referred to Cognito Health. The
            client will receive an email and a text message to create their account. Click below to
            refer another client or log out from the portal in the top right.`}
          </Typography>
          {referNewClientButton()}
        </Box>
      )}
    </Box>
  );
};

export default memo(ClientIntake);
