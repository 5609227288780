import { Box } from "@material-ui/core";
import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";
import {
  Button,
  Edit,
  RaRecord,
  SaveButton,
  SimpleForm,
  TextInput,
  TopToolbar,
  email,
  required,
  useGetRecordId,
} from "react-admin";
import { colors } from "../../app/colors";
import { ConfirmationDialog } from "../../app/ConfirmationDialog";
import { REACT_APP_AUTH0_AUDIENCE, useHttpClient } from "../../utils";

const validateEmail = [required(), email()];

export const UserEdit = props => {
  const recordId = useGetRecordId();
  const { httpClient } = useHttpClient();

  const [showToast, setShowToast] = useState("");
  // Typically RA would handle this, but there's some weird edge cases where if
  // you change the email, then change it back to the original email, the save
  // button isn't enabled. So instead we control if the save button is enabled
  // or not.
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [formEmail, setFormEmail] = useState("");
  const [emailButtonDisabled, setEmailButtonDisabled] = useState(false);
  const [isSendingWelcomeEmail, setIsSendingWelcomeEmail] = useState(false);

  const handleSubmit = async (values: any) => {
    try {
      await httpClient(
        `${REACT_APP_AUTH0_AUDIENCE}/profile/update_email_via_admin/${values.id}`,
        {
          method: "POST",
          body: JSON.stringify({
            email: values.email,
          }),
        }
      );
      setSaveButtonDisabled(true);
      setShowToast("success");
      setFormEmail(values.email);
      setEmailButtonDisabled(false);
    } catch (e: any) {
      setShowToast(e.message);
    }
  };

  const sendWelcomeEmail = async () => {
    setEmailButtonDisabled(true);
    setIsSendingWelcomeEmail(true);
    try {
      await httpClient(
        `${REACT_APP_AUTH0_AUDIENCE}/send_welcome_email_via_admin`,
        {
          method: "POST",
          body: JSON.stringify({
            userId: recordId,
          }),
        }
      );
      setShowToast("emailSent");
    } catch (e: any) {
      setShowToast(e.message);
    }
    setEmailButtonDisabled(false);
    setIsSendingWelcomeEmail(false);
  };

  const Toolbar = () => (
    <TopToolbar sx={{ display: "flex", justifyContent: "space-between" }}>
      <SaveButton color="primary" disabled={saveButtonDisabled} />
      <Button
        label="Send Welcome Email"
        disabled={emailButtonDisabled}
        onClick={
          showConfirmationDialog
            ? undefined
            : () => setShowConfirmationDialog(true)
        }
        variant="contained"
        sx={{
          backgroundColor: colors.green.secondary,
          "&:hover": {
            backgroundColor: colors.green.primary,
          },
        }}
      />
    </TopToolbar>
  );

  const confirmationContent = (record: RaRecord) => {
    return (
      <Box
        display="flex"
        alignItems={"center"}
        justifyContent={"center"}
        width={"30rem"}
      >
        {isSendingWelcomeEmail ? (
          <>Sending welcome email...</>
        ) : (
          <>
            Confirm that you want to send a welcome email (which contains a
            change password link) to
            <span>
              <strong> {formEmail ? formEmail : record.email}</strong>
            </span>
          </>
        )}
      </Box>
    );
  };

  const closeConfirmationDialog = () => {
    setShowConfirmationDialog(false);
  };

  const onConfirmationDialogClose = async (confirmed: boolean) => {
    if (!confirmed) {
      closeConfirmationDialog();
      return;
    }
    await sendWelcomeEmail();
    closeConfirmationDialog();
  };

  return (
    <Edit {...props} title={"Edit User"}>
      <SimpleForm onSubmit={handleSubmit} toolbar={<Toolbar />}>
        {showConfirmationDialog && (
          <ConfirmationDialog
            open
            onCloseDialog={onConfirmationDialogClose}
            content={confirmationContent}
            disableCancel={isSendingWelcomeEmail}
            disabledOk={isSendingWelcomeEmail}
          />
        )}
        <TextInput
          onChange={() => {
            setSaveButtonDisabled(false);
            setEmailButtonDisabled(true);
          }}
          source="email"
          validate={validateEmail}
          fullWidth
        />
        {!!showToast && (
          <Snackbar
            open
            autoHideDuration={5000}
            onClose={() => {
              setShowToast("");
            }}
          >
            <Alert
              onClose={() => {
                setShowToast("");
              }}
              severity={
                showToast === "success" || showToast === "emailSent"
                  ? "success"
                  : "error"
              }
              sx={{ width: "100%" }}
            >
              {showToast === "success"
                ? `Successfully updated client details. If their email was updated, they should be using the new email for logging in.`
                : showToast === "emailSent"
                ? `Successfully sent welcome email.`
                : showToast}
            </Alert>
          </Snackbar>
        )}
      </SimpleForm>
    </Edit>
  );
};
