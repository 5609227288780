export const colors = {
  green: {
    primary: "#006668",
    secondary: "#55999A",
  },
  pink: {
    primary: "#FF867E",
  },
  blue: {
    light: "#F1F8FD",
    dark: "#041b48",
  },
  grey: {
    light: "#eeeeee",
    medium: "#bdbdbd",
  },
  white: "#fafafa",
  status: {
    error: "#ff533e",
    warning: "#ffa14c",
    success: "#5da758",
    info: "#41a2ff",
    inProgress: "#b182c4",
  },
};
