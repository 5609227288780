import {
  attentionQuestions,
  hyperActivityAndImpulsivityQuestions,
} from "./questions";
import { WeissSymptomRecordIIForm, WeissSymptomRecordIIScores } from "./types";
import { convertBooleanToYesNo } from "../utils";

const convertToScore = (score: WeissSymptomRecordIIScores) => {
  if (score === -1) {
    return "N/A";
  }
  if (score === 0) {
    return "None";
  }
  if (score === 1) {
    return "Mild";
  }
  if (score === 2) {
    return "Moderate";
  }
  if (score === 3) {
    return "Severe";
  }
};

export const ExtendedWeissSymptomRecordIIAnswersText = (
  data: WeissSymptomRecordIIForm
) => {
  let text = "";
  const addText = (newText: string, newLine?: boolean) => {
    text += `${newLine ? "\n" : ""}${newText}\n`;
  };

  [...attentionQuestions, ...hyperActivityAndImpulsivityQuestions].forEach(
    (questionSet, index) => {
      // If N/A, None, or Mild severity and they didn't have the symptom as
      // a child don't show further details.
      if (
        (data[questionSet.field] === -1 ||
          data[questionSet.field] === 0 ||
          data[questionSet.field] === 1 ||
          data[questionSet.field] === undefined ||
          data[questionSet.field] === null) &&
        !data[`${questionSet.field}AsChild`]
      ) {
        return text;
      }

      addText(
        `${questionSet.question}  - ${convertToScore(
          data[questionSet.field] as WeissSymptomRecordIIScores
        )}`,
        // Only add new line if not the first questions
        index !== 0
      );
      if (data[`${questionSet.field}DailyImpact`]) {
        addText(`Impact as adult - ${data[`${questionSet.field}DailyImpact`]}`);
      }
      if (data[`${questionSet.field}AsChild`] === false) {
        addText(
          `Experienced as a child - ${convertBooleanToYesNo(
            data[`${questionSet.field}AsChild`]
          )}`
        );
      }
      if (
        data[`${questionSet.field}AsChild`] &&
        data[`${questionSet.field}ChildhoodImpact`]
      ) {
        addText(
          `Impact as child - ${data[`${questionSet.field}ChildhoodImpact`]}`
        );
      }
    }
  );

  if (data.ageWhenAttentionSymptomsBegan) {
    addText(
      `Age when attention ADHD symptoms began - ${data.ageWhenAttentionSymptomsBegan}`,
      true
    );
  }
  if (data.ageWhenHyperactivitySymptomsBegan) {
    addText(
      `Age when hyperactivity ADHD symptoms began - ${data.ageWhenHyperactivitySymptomsBegan}`
    );
  }
  return text;
};
