import styled from "styled-components";
import { colors } from "./colors";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${colors.grey.light};
  border-style: dashed;
  background-color: ${colors.white};
  color: ${colors.grey.medium};
  outline: none;
  transition: border 0.24s ease-in-out;
`;
